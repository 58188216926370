import { render, staticRenderFns } from "./CertifPart.vue?vue&type=template&id=5fb68451&scoped=true&"
import script from "./CertifPart.vue?vue&type=script&lang=js&"
export * from "./CertifPart.vue?vue&type=script&lang=js&"
import style0 from "./CertifPart.vue?vue&type=style&index=0&id=5fb68451&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb68451",
  null
  
)

export default component.exports