<template>
  <v-row>
    <!-- Deskripsi -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Deskripsi</span>
      </div>
      <vue-editor
        :editorToolbar="customToolbar"
        v-model="data.description"
        class="overflow-y-auto"
        style="max-height: 400px"
        placeholder="Masukan deskripsi"
      ></vue-editor>
    </v-col>
    <!-- Google Drive -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span>Tampilkan link google drive</span>
      </div>
      <v-radio-group v-model="data.link_embed.is_showing">
        <v-radio :value="true">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="false">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span>Link Google Drive</span>
      </div>
      <v-text-field
        placeholder="Masukkan link google drive"
        v-model="data.link_embed.url"
      ></v-text-field>
    </v-col>
    <!-- Meet -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span>Sembunyikan link meeting</span>
      </div>
      <v-radio-group v-model="data.meeting.is_must_participate">
        <v-radio :value="true">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="false">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>
    <!-- link -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span>Link Meeting</span>
      </div>
      <v-text-field
        placeholder="Masukkan link meeting"
        v-model="data.meeting.link"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Jadwal</span>
      </div>
      <div class="col-md-12 py-0">
        <div class="row">
          <div class="col-md-12 ps-0">
            <v-select
              :items="waktu"
              :item-text="'name'"
              :item-value="'value'"
              v-model="data.meeting.is_day"
              placeholder="Pilih Waktu"
              class="mb-2 mr-2"
              @change="update()"
            ></v-select>
          </div>
          <div v-if="this.data.meeting.is_day">
            <div v-for="(item, index) in days" :key="index">
              <div class="row">
                <div class="col-md-5">
                  <div class="d-flex">
                    <v-select
                      :items="hari"
                      :item-text="'value'"
                      :item-value="'name'"
                      prepend-inner-icon="ri-calendar-event-line"
                      v-model="item.day"
                      placeholder="Pilih hari"
                      class="mb-2 mr-2"
                    ></v-select>
                    <v-btn icon @click="days.splice(index, 1)"
                      ><v-icon>$delete</v-icon></v-btn
                    >
                  </div>
                </div>
                <div class="col-md-7 pt-0">
                  <v-row>
                    <v-col
                      cols="12"
                      v-for="(times, index2) in item.times"
                      :key="index2"
                      class="d-flex"
                    >
                      <v-text-field
                        v-model="times.start_time"
                        prepend-inner-icon="ri-time-line"
                        placeholder="Masukkan waktu mulai"
                        class="mr-2 mb-2"
                      ></v-text-field>
                      <v-text-field
                        v-model="times.end_time"
                        prepend-inner-icon="ri-time-line"
                        placeholder="Masukkan waktu selesai"
                        class="mb-2"
                      ></v-text-field>
                      <v-btn
                        icon
                        @click="
                          item.times.length == 1
                            ? days.splice(index, 1)
                            : item.times.splice(index2, 1)
                        "
                        ><v-icon>$delete</v-icon></v-btn
                      >
                    </v-col>

                    <v-col cols="12">
                      <v-btn outlined @click="item.times.push({})"
                        >+ tambah waktu</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
            <v-col cols="12">
              <v-btn
                outlined
                @click="
                  days.push({
                    day: '',
                    start_time: '',
                    end_time: '',
                    times: [{}],
                  })
                "
                >+ tambah hari</v-btn
              >
            </v-col>
          </div>
          <div v-else class="col-md-12">
            <div v-for="(item, index) in dates" :key="index + 'D'">
              <div class="row">
                <div class="col-md-5 ps-0">
                  <div class="d-flex">
                    <v-menu
                      v-model="showPicker[index]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="currentDateTime(item.date)"
                          @input="item.date = $event.target.value"
                          prepend-inner-icon="ri-calendar-line"
                          placeholder="Masukkan tanggal"
                          readonly
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.date"
                        @change="currentDateTime(item.date)"
                        no-title
                        @input="showPicker[index] = false"
                      ></v-date-picker>
                    </v-menu>

                    <v-btn icon @click="dates.splice(index, 1)"
                      ><v-icon>$delete</v-icon></v-btn
                    >
                  </div>
                </div>
                <div class="col-md-7 pt-0">
                  <v-row>
                    <v-col
                      cols="12"
                      v-for="(times, index2) in item.times"
                      :key="index2"
                      class="d-flex"
                    >
                      <v-text-field
                        prepend-inner-icon="ri-time-line"
                        v-model="times.start_time"
                        placeholder="Masukkan waktu mulai"
                        class="mr-2 mb-2"
                      ></v-text-field>
                      <v-text-field
                        prepend-inner-icon="ri-time-line"
                        v-model="times.end_time"
                        placeholder="Masukkan waktu selesai"
                        class="mb-2"
                      ></v-text-field>

                      <v-btn
                        icon
                        @click="
                          item.times.length == 1
                            ? dates.splice(index, 1)
                            : item.times.splice(index2, 1)
                        "
                        ><v-icon>$delete</v-icon></v-btn
                      >
                    </v-col>

                    <v-col cols="12">
                      <v-btn outlined @click="item.times.push({})"
                        >+ tambah waktu</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
            <v-col cols="12">
              <v-btn outlined @click="dates.push({ date: '', times: [{}] })"
                >+ tambah tanggal</v-btn
              >
            </v-col>
          </div>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Jadwal Selesai Aktivitas</span>
      </div>
      <div class="col-md-12 py-0">
        <div class="row">
          <div class="col-md-12 ps-0">
            <v-select
              :items="waktu"
              :item-text="'name'"
              :item-value="'nilai'"
              v-model="finish_activity.is_day"
              placeholder="Pilih Waktu"
              class="mb-2 mr-2"
            ></v-select>
          </div>
          <div v-if="finish_activity.is_day == 2" class="col-md-12">
            <div v-for="(item, index) in finish_activity.dates" :key="index">
              <div class="row">
                <div class="col-md-5 ps-0">
                  <v-menu
                    v-model="showPickerFinish[index]"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="currentDateTime(item.date)"
                        @input="item.date = $event.target.value"
                        append-icon="ri-calendar-line"
                        placeholder="Masukkan tanggal"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.date"
                      @change="currentDateTime(item.date)"
                      no-title
                      @input="showPickerFinish[index] = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <!-- <div class="col-md-6 d-flex"> -->
                <div class="col-md-6 d-flex">
                  <v-text-field
                    v-model="item.start_time"
                    placeholder="Masukkan waktu mulai"
                    class="mr-2 mb-2"
                  ></v-text-field>
                  <v-text-field
                    v-model="item.end_time"
                    placeholder="Masukkan waktu selesai"
                    class="mb-2"
                  ></v-text-field>
                </div>
                <!-- </div> -->
                <v-btn
                  icon
                  @click="finish_activity.dates.splice(index, 1)"
                  class="mt-2"
                  ><v-icon>$delete</v-icon></v-btn
                >
              </div>
            </div>

            <v-col cols="12">
              <v-btn
                outlined
                @click="
                  finish_activity.dates.push({
                    date: '',
                    start_time: '',
                    end_time: '',
                  })
                "
                >+ Tambah waktu</v-btn
              >
            </v-col>
          </div>
          <div v-else-if="finish_activity.is_day == 1" class="col-md-12">
            <div v-for="(item, index) in finish_activity.days" :key="index">
              <div class="row">
                <div class="col-md-5 ps-0">
                  <v-select
                    :items="hari"
                    :item-text="'value'"
                    :item-value="'name'"
                    v-model="item.day"
                    placeholder="Pilih hari"
                    class="mb-2 mr-2"
                  ></v-select>
                </div>
                <!-- <div class="col-md-6 d-flex"> -->
                <div class="col-md-6 d-flex">
                  <v-text-field
                    v-model="item.start_time"
                    placeholder="Masukkan waktu mulai"
                    class="mr-2 mb-2"
                  ></v-text-field>
                  <v-text-field
                    v-model="item.end_time"
                    placeholder="Masukkan waktu selesai"
                    class="mb-2"
                  ></v-text-field>
                </div>
                <v-btn
                  icon
                  @click="finish_activity.days.splice(index, 1)"
                  class="mt-2"
                  ><v-icon>$delete</v-icon></v-btn
                >
                <!-- </div> -->
              </div>
            </div>
            <v-col cols="12">
              <v-btn
                outlined
                @click="
                  finish_activity.days.push({
                    day: '',
                    start_time: '',
                    end_time: '',
                  })
                "
                >+ tambah waktu</v-btn
              >
            </v-col>
          </div>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Metadata</span>
      </div>
      <template v-for="(meta, index) in meta_data">
        <div
          v-if="!(meta.title || '').includes('finish_activity')"
          :key="index"
        >
          <div class="d-flex">
            <v-text-field
              v-model="meta.title"
              placeholder="Masukkan judul"
              class="mr-2 mb-2"
            ></v-text-field>
            <v-text-field
              v-model="meta.content"
              placeholder="Masukkan konten"
              class="mb-2"
            ></v-text-field>
            <v-btn icon @click="meta_data.splice(index, 1)"
              ><v-icon>$delete</v-icon></v-btn
            >
          </div>
        </div>
      </template>
      <v-col cols="12">
        <v-btn outlined @click="meta_data.push({})">+ tambah metadata</v-btn>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { VueEditor } from "vue2-editor";
import model from "../_mixins/model";
import moment from "moment";

export default {
  components: { VueEditor },
  mixins: [model],
  data: () => ({
    activePicker: null,
    date: null,
    menu: false,
    showPicker: {},
    showPickerFinish: {},

    overlay: 0.1,
    overlay_color: "secondary",
    file: null,
    meeting: {},
    days: [],
    dates: [],
    time: {},
    is_day: null,
    meta_data: [],
    finish_activity: {
      is_day: "1",
      dates: [{ date: null, start_time: null, end_time: null }],
      days: [{ day: null, start_time: null, end_time: null }],
    },
    waktu: [
      {
        name: "Berdasarkan Hari",
        value: true,
        nilai: "1",
      },
      {
        name: "Berdasarkan Tanggal",
        value: false,
        nilai: "2",
      },
    ],
    hari: [
      {
        name: "sunday",
        value: "Minggu",
      },
      {
        name: "monday",
        value: "Senin",
      },
      {
        name: "tuesday",
        value: "Selasa",
      },
      {
        name: "wednesday",
        value: "Rabu",
      },
      {
        name: "thursday",
        value: "Kamis",
      },
      {
        name: "friday",
        value: "Jumat",
      },
      {
        name: "saturday",
        value: "Sabtu",
      },
    ],
  }),
  computed: {
    customToolbar() {
      return [];
    },
  },
  watch: {
    meeting: {
      handler(val) {
        this.$set(this.data, "meeting", { ...this.data.meeting, ...val });
        // this.$set(this.data, "meeting", { ...this.data.meeting.days, ...val });
        // this.$set(this.data.meeting, "days", val.days);
      },
      deep: true,
    },
    is_day: {
      handler(val) {
        this.$set(this.data.meeting, "is_day", val);
      },
      deep: true,
    },
    days: {
      handler(val) {
        this.$set(this.data.meeting, "days", { ...this.days, ...val });
        // this.$set(this.data.meeting, "days", val);
      },
      deep: true,
    },
    dates: {
      handler(val) {
        const value = { ...this.dates, ...val };
        Object.keys(value).forEach((index) => {
          value[index].date = value[index].date.slice(0, 10);
        });
        this.$set(this.data.meeting, "dates", value);
      },
      deep: true,
    },
    meta_data: {
      handler(val) {
        // this.$set(this.data, "meta_data", { ...this.data.meta_data, ...val });
        this.$set(this.data, "meta_data", val);
      },
      deep: true,
    },
    finish_activity: {
      handler(val) {
        const value = {
          title: "finish_activity",
          content: JSON.stringify(val),
        };
        if (this.data.meta_data) {
          const dataFinish = this.data.meta_data.findIndex(
            (v) => v.title == "finish_activity"
          );
          if (dataFinish == -1) this.data.meta_data.push(value);
          else this.$set(this.data.meta_data, dataFinish, value);
        } else this.data.meta_data.push(value);
      },
      deep: true,
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  // filters: {
  //   currentDateTime: function (value) {
  //     return moment(value).format("L");
  //   }
  // },
  methods: {
    currentDateTime(a) {
      return a ? moment(a).locale("en-ca").format("L") : null;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    deleteMeta(id) {
      this.meta_data.splice(this.meta_data.indexOf(id), 1);
    },
    addMeta() {
      this.data.meta_data.push([{ title: "", content: "" }]);
    },
    deleteTime(id) {
      this.data.meeting.days.splice(this.data.meeting.days.indexOf(id), 1);
    },
    addTime() {
      this.days.push({
        day: "",
        times: [{}],
      });
    },
    update() {
      if (this.data.meeting.is_day === true) {
        this.dates = [
          { date: null, times: [{ start_time: "", end_time: "" }] },
        ];
      } else {
        this.days = [{ day: "", times: [{ start_time: "", end_time: "" }] }];
      }
    },
  },
  mounted() {},
  created() {
    this.data.link_embed = this.data.link_embed
      ? this.data.link_embed
      : { is_showing: "", url: "" };
    this.data.meeting = this.value?.meeting || {};
    this.meta_data = this.data?.meta_data ? this.data.meta_data : [{}];
    this.days = this.data?.meeting.days
      ? this.data.meeting.days
      : [{ day: "", times: [{}] }];
    this.dates = this.data?.meeting.dates
      ? this.data.meeting.dates
      : [{ date: null, times: [{}] }];

    this.$set(
      this.meeting,
      "is_must_participate",
      this.data.meeting?.is_must_participate
    );
    this.$set(this.meeting, "is_day", this.data.meeting?.is_day);

    const finish_activity = this.data.meta_data?.find(
      (v) => v.title == "finish_activity"
    );

    if (finish_activity && finish_activity.content)
      this.finish_activity = JSON.parse(finish_activity.content);
    if (!this.finish_activity.dates) this.finish_activity.dates = [{}];
    if (!this.finish_activity.days) this.finish_activity.days = [{}];

    this.update();
  },
};
</script>
